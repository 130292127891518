import React from 'react';
import styled from 'styled-components';
import * as Forms from '../../components/forms/controlled';
import { useAncillaryApi } from "../../api/useAncillaryApi";
import { createRoute, makeScreen } from "../../core/services";
import { useQuery } from "../../routing/useQuery";
import { Layout } from "../../components/Layout";
import {
  fetchIdle,
  fetchError,
  fetchLoading,
  FetchState,
  fetchSuccess,
  stateHasData,
  stateHasError,
  FetchStateType
} from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import { AncillaryApiClient } from '../../api/ancillaryApiMiddleware';
import { RouteLink } from '../../routing/components/RouteLink';
import { ancillaryViewScreen } from "./AncillaryView";
import { ancillaryCreateScreen } from "./AncillaryCreate";
import { useServices } from "../../core/context/services";
import { renderRouteUrl } from "../../core";
import { OrnSearch } from "../../components/inputs";
import { useFormHelpers } from "../../components/forms/controlled/hooks";
import { locateAll, AnyOrnLocateResponse } from "@openstax/orn-locator";
import { SelectAncillaryType } from "../../ancillary-types/components";
import { Html } from "../../components/Html";
import { PUBLICATION_STATES } from '../../ancillary-types/components/AncillaryTypeForm';

type SearchData = Awaited<ReturnType<typeof executeSearch>>;

type Filter = {key: string; value: string | string[] | boolean};

type SearchOptions = {
  page?: string;
  query?: string;
  tags?: string;
};

const executeSearch = (apiClient: AncillaryApiClient, options: SearchOptions) => {
  return apiClient.apiV0SearchAncillaries({query: options})
    .then(response => response.acceptStatus(200))
    .then(response => response.load());
};

const useSearch = (options: SearchOptions & {must?: Filter[]}) => {
  const apiClient = useAncillaryApi();
  const setAppError = UI.useSetAppError();
  const [pageData, setPageData] = React.useState<FetchState<SearchData, string>>(fetchLoading());

  React.useEffect(() => {
    executeSearch(apiClient, options)
      .then(data => setPageData(fetchSuccess(data)))
      .catch(e => {
        setPageData(previous => fetchError('connection failed', previous));
        setAppError(e);
      })
    ;
  }, [apiClient, options, setAppError]);

  return pageData;
};

const Tag = styled.span`
  background: #ddd;
  padding: 0.3rem;
  border-radius: 0.3rem;

  & + span {
    margin-left: 0.3rem;
  }
`;

const NoResults = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
`;

const SearchResults = ({searchData, options}: {searchData: SearchData; options: SearchOptions}) => {
  const {currentPage, totalPages} = searchData.meta;

  return <div>
    {searchData.items.length
      ? <ol>
        {searchData.items.map(item => <li key={item.id}>
          <span>{item.type.name}: </span>
          <RouteLink route={ancillaryViewScreen} params={{id: item.id}}>
            <Html>{item.name}</Html>
          </RouteLink>
          <Html block>{item.description}</Html>
          <small>{Object.entries(item.tagLabels).map(([tag, tagLabel]) => <Tag key={tag}>{tagLabel}</Tag>)}</small>

        </li>)}
      </ol>
    : <NoResults>Sorry, no results found.</NoResults>}
    {currentPage > 1
      ? <RouteLink route={ancillaryListScreen} query={{...options, page: String(currentPage - 1)}}>prev page</RouteLink>
      : null
    }
      {currentPage < totalPages
      ? <RouteLink route={ancillaryListScreen} query={{...options, page: String(currentPage + 1)}}>next page</RouteLink>
      : null
    }
  </div>;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;

  ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;
const Sidebar = styled.div`
  width: 20rem;
`;
const MainContent = styled.div`
  flex: 1;

  li {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
  }
  li small {
    line-height: 2.3rem;
  }
`;

const coerceArray = (thing: undefined | string | string[]): string[] => {
  if (thing instanceof Array) {
    return thing;
  } else if (typeof thing === 'string') {
    return [thing];
  }

  return [];
};

const OrnTagSelect = () => {
  const {data, setInput, submit} = useFormHelpers();
  const selected: string[] = React.useMemo(() => coerceArray(data.tags), [data.tags]);
  const [resources, setResources] = React.useState<
    FetchState<AnyOrnLocateResponse[], string>
  >(fetchIdle());
  const firstRun = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (!firstRun.current) {
      submit();
    }

    firstRun.current = false;

    locateAll(selected).then(results => {
      setResources(fetchSuccess(results));
    });
  }, [selected, submit]);

  return <Forms.FormSection>
    <OrnSearch onSelect={orn => {
      setInput.fields(previous => ({...previous, tags: [...(previous.tags || []), orn]}));
    }} />

    {resources.type === FetchStateType.LOADING
      ? <UI.Loader />
      : stateHasData(resources) ? <ul>
        {resources.data.map(resource => {
          const title = 'contextTitle' in resource
            ? resource.contextTitle : 'title' in resource ? resource.title
            : resource.orn;

          return <li key={resource.orn}>
            <button
              onClick={() => {
                setInput.fields(previous =>
                  ({...previous, tags: coerceArray(previous.tags).filter((tag: string) => tag !== resource.orn)})
                );
              }}
              type="button"
            >remove</button>
            {'urls' in resource
              ? <a href={resource.urls.main} target="_blank" rel="noreferrer">{title}</a>
              : title
            }
          </li>;
        })}
      </ul> : null
    }
  </Forms.FormSection>;
};

export const AncillaryList = () => {
  const options = useQuery();
  const services = useServices();
  const searchState = useSearch(options);

  const searchFormState: FetchState<SearchOptions, string> = React.useMemo(() => ({
    ...searchState,
    data: options,
  }), [options, searchState]);

  const onSubmitSearch = React.useCallback((data: SearchOptions) => {
    services.history.push(renderRouteUrl(ancillaryListScreen, undefined, data));
  }, [services]);

  return <Layout title="Ancillary List">
    <Row>
      <Sidebar>
        <Forms.Form state={searchFormState} onSubmit={onSubmitSearch}>
          <Forms.TextInput name="query" label="Search" />
          <SelectAncillaryType name="type.id" label="Type" />
          <Forms.Select
            label="Publication state"
            name="publicationState"
            options={['', ...PUBLICATION_STATES].map(option => ({label: option, value: option}))}
          />
          <Forms.Buttons />
          <OrnTagSelect />
        </Forms.Form>

      </Sidebar>
      <MainContent>
        <RouteLink route={ancillaryCreateScreen}>create new</RouteLink>
        {stateHasError(searchState) ? searchState.error : null}
        {stateHasData(searchState) ? <SearchResults searchData={searchState.data} options={options}/> : <UI.Loader />}
      </MainContent>
    </Row>
  </Layout>;
};

export const ancillaryListScreen = createRoute({name: 'AncillaryList', path: '/',
  handler: makeScreen(AncillaryList)
});
